import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Molbitzer Turniere', // e.g: 'Name | Developer'
  lang: 'de', // e.g: en, es, fr, jp
  description:
    'Turniere aus der ostthürigischen Provinz. Mehrmals jährlich werden in Molbitz und Umgebung verschiedene Freizeitturniere veranstaltet. Darunter das Molbitzer Fifa-Turnier, der Molbitzer Dart-Cup oder das Fussball-Turnier in Altenburg.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'fifa.jpg',
    title: 'Fifa-Turnier',
    info: 'Das Flaggschiff der Molbitzer-Turniere. Seit 2015 wird hier der Titel des besten ostthüringischen Fifa-Spielers ermittelt. ',
    info2:
      'Durch die faire Teamvergabe hat jeder Teilnahmer die Möglichkeit den Pokal mit nach Hause zu nehmen und so kam es auch zu der ein oder andern Überraschung in den letzten Jahren. Eine Übersicht aller Ergebnisse findet ihr auf der eigenen Website.',
    url: 'https://fifa.molbitzer-turniere.de',
  },
  {
    id: nanoid(),
    img: 'darts.jpg',
    title: 'Darts-Turnier',
    info: 'Im Jahr 2020 feierte der Molbitzer-Dart-Cup sein Debüt. Ein Junge und ein Mädchen treten jeweils in einem Team an um am Ende gemeinsam die Baustoff-Wiechmann-Trophy in die Höhe zu stemmen.',
    info2:
      'Mit einem Turnierpreisgeld von 200€ zählt das Turnier zu einem der höchstdotiertesten Dart-Turniere in Ostthüringen. ',
  },
  {
    id: nanoid(),
    img: 'fussball-turnier.jpg',
    title: 'Fussball-Turnier',
    info: 'Erstmal wurde im Juli 2021 das Molbitzer-Fussball-Turnier ausgetragen. Nachdem der Amateursport durch die Corona-Pause für sechs Monate still stand, traten 13 Teams auf dem Kunstrasenplatz von Motor Altenburg an, um zu zeigen wer die Pause am effektivsten genutzt hat.',
    info2:
      'Am Ende durfte das Team Energie Kopfnuss um Kapitän Fabian Baur den Turniersieg feiern.',
  },
  {
    id: nanoid(),
    img: 'formel1.jpg',
    title: 'F1-League',
    info: 'Einmal monatlich treffen sich 10-20 Rennspielenthusiasten um in drei Rennen der Rennsimulation F1 2020 den besten Rennfahrer unter sich auszumachen. Die F1-League befindet sich derzeit in der zweiten Season.',
    info2: 'Maximilian Bielecke stand als Sieger der ersten Saison nach 23 Rennen fest.',
    // url: 'https://f1.scalangular.de',
  },
  {
    id: nanoid(),
    img: 'fifa-league.jpg',
    title: 'Fifa-League',
    info: 'Neben dem jährlichen Fifa-Turnier gibt es auch eine Fifa-Liga die mittlerweile in drei eigene Ligen eingeteilt ist. Auch hier messen sich ein bis zweimal monatlich die Teilnehmer in der Fussballsimulation Fifa 21. Das ganze findet zum großen Teil online statt und es wird hauptsächlich auf Playstation und XBOX gespielt.',
    info2: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
